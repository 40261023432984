import React from "react";
import { GiftGridItem } from "./GiftGridItem";
import { useFetchGift } from "../hooks/useFetchGift";

export const GiftGrid = ({ category }) => {
  const { data, loading } = useFetchGift(category);

  return (
    <div className="gifted">
      <h2>{category}</h2>
      {loading && <div className="loader"></div>}
      <div className="giftGrid">
        {data.map((img) => (
          <GiftGridItem key={img.id} {...img} />
        ))}
      </div>
    </div>
  );
};
